<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-5 col-12">Event Setting</div>
    <div class="
            col-lg-7 col-12
            text-end
            d-flex
            justify-content-end
            custom-flex-cloumn-mob
          ">
    </div>
  </div>
  <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
      <button class="nav-link " id="sms-event-setting-tab" data-bs-toggle="tab" data-bs-target="#smseventdetail"
        type="button" role="tab" aria-controls="vehicle-details" @click="redirectsmseventurl()" aria-selected="false">
        SMS
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link active" id="email-event-setting-tab" data-bs-toggle="tab" data-bs-target="#emaileventdetail"
        type="button" role="tab" aria-controls="customer-details" aria-selected="true">
        Email
      </button>
    </li>
  </ul>
  <div class="tab-content mt-2" id="myTabContent">
    <div class="tab-pane fade show active" id="smseventdetail" role="tabpanel" aria-labelledby="sms-event-setting-tab">
      <div class="company-section-outer">
        <div class="layout-content-section">

          <div class="custom-ultima-datatable" style="height: calc(100vh - 188px)">
            <DataTable :value="emaileventList" :scrollable="true" columnResizeMode="fit" scrollHeight="flex"
              :paginator="true" :rows="30" responsiveLayout="scroll" :lazy="true" dataKey="ta1" :loading="loading"
              :rowHover="true" :totalRecords="totalRecords" @page="changePage($event)"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
              <template v-if="!loading" #empty>No records found.</template>
              <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
              <Column field="eventname" header="Event Name" headerStyle="width: 30%" bodyStyle="width: 30%">
                <template #body="{ data }">
                  <div class="text-capitalize">
                    {{ data.ta12 ? data.ta12 : "N/A" }}
                  </div>
                </template>
              </Column>
              <Column field="emailtemplate" header="Email Subject" headerStyle="width: 45%" bodyStyle="width: 45%">
                <template #body="{ data }">
                  <div class="text-capitalize">
                    {{ data.tb5 ? data.tb5 : "N/A" }}
                  </div>
                </template>
              </Column>
              <Column field="emailstatus" header="Email Status" headerStyle="width: 15%" bodyStyle="width: 15%">
                <template #body="{ data }">
                  <div>
                    <InputSwitch v-model="data.tb4" :true-value="dynamicTrueActiveValue"
                      :false-value="dynamicFalseInactiveValue" @change="changeEmailStatus(data)" />
                  </div>
                </template>
              </Column>
              <Column field="action" header="Action" class="justify-content-center" headerStyle="width: 10%"
                bodyStyle="width: 10%">
                <template #body="{ data }">
                  <button type="button" title="View Details" class="btn custom-outline-view-btn me-2"
                    @click="viewEmailDetailModalOpen(data)">
                    <i class="pi pi-eye call-btn-color"></i>
                  </button>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
    <div class="tab-pane fade" id="emaileventdetail" role="tabpanel" aria-labelledby="email-event-setting-tab">
      Email Event Setting
    </div>
  </div>
  <!-- View Email Body start here -->
  <div class="modal-mask" v-if="viewemaildetailmodalstatus">
    <div class="modal-md modal-dialog-scrollable modal-fullscreen
        ms-auto
        custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Email Details</h5>
          <button type="button" class="btn-close" @click="viewEmailDetailModalClose()"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="email-label-group ">
                <span class="from-label-value">Subject : </span>
                <span class="form-label">{{ emailsubject ? emailsubject : 'N/A' }}</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="email-label-group ">
                  <div class="from-label-value mb-2">Email Body :</div>
                  <div class="form-label">{{ emailbody ? emailbody : 'N/A' }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- View Email Body End here -->
</template>
    
<script>
import ApiService from "../../service/ApiService";

export default {
  data() {
    return {
      loading: false,
      emaileventList: null,
      totalRecords: 0,
      viewemaildetailmodalstatus: false,
      emailsubject: '',
      emailbody: '',
      switchuncheck: false,
      dynamicTrueActiveValue: 1,
      dynamicFalseInactiveValue: 0,
    };
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },
  mounted() {
    this.geteventlist({ status: 1 });
  },
  methods: {
    viewEmailDetailModalOpen(e) {
      this.viewemaildetailmodalstatus = true;
      this.emailsubject = e.tb5;
      this.emailbody = e.ta7;
    },
    viewEmailDetailModalClose() {
      this.viewemaildetailmodalstatus = false;
      this.emailsubject = '';
      this.emailbody = '';
    },
    redirectsmseventurl() {
      this.$router.push("/eventsettings/sms");
    },
    geteventlist(item) {
      this.loading = true;
      this.ApiService.geteventlist(item).then((data) => {
        if (data.status == 200) {
          this.emaileventList = data.data;
          this.totalRecords = data.count;
          this.loading = false;
        } else {
          this.loading = false;
          this.emaileventList = null;
          this.totalRecords = 0;
        }
      });
    },
    changePage(event) {
      this.page_no = event.page;
      this.geteventlist({ page_no: this.page_no, status: 1 });
    },
    changeEmailStatus(data) {
      let fields = {};
      fields["tb1"] = data.ta1;
      if (data.tb4 == 0 || data.tb4 == false) {
        fields["tb4"] = 0;
      }
      if (data.tb4 == 1 || data.tb4 == true) {
        fields["tb4"] = 1;
      }
      fields["status"] = 1;
      this.ApiService.updateeventstatus(fields).then((items) => {
        if (items.status === 200) {
          var successMsg = items.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.geteventlist({ status: 1 });
        } else {
          var errorMsg = items.message;
          this.$toast.open({
            message: errorMsg,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
        }
      });
    },
  },
};
</script>
    
<style scoped>
.email-label-group {
  padding: 8px 16px;
  background: #e4e7ff 0% 0% no-repeat padding-box;
  border: 1px solid #bec2db;
  margin-bottom: 10px;
}

.email-label-group .form-label {
  font-family: "AcuminPro-Regular";
  font-size: 16px;
  letter-spacing: 0px;
  color: #4a5463;
  margin-bottom: 6px;
}

.email-label-group .from-label-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 16px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 22px;
}

.status-active {
  background: #c8e6c9;
  border-radius: 2px;
  letter-spacing: 0.3px;
  color: #256029;
  opacity: 1;
  font-size: 12px;
  font-family: "AcuminPro-Bold";
  padding: 0.3em 0.2rem 0.2em 0.2rem;
  text-transform: capitalize;
  border: 1px solid #4daa53;
  line-height: 12px;
}

.status-inactive {
  background: #ffcdd2;
  border-radius: 2px;
  letter-spacing: 0.3px;
  color: #c63737;
  opacity: 1;
  font-size: 12px;
  font-family: "AcuminPro-Bold";
  padding: 0.3em 0.2rem 0.2em 0.2rem;
  text-transform: capitalize;
  border: 1px solid #d7a5a5;
  line-height: 12px;
}

.filter-apply-btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  font-family: "AcuminPro-Regular";
  line-height: 13px;
  height: 34px;
}

.filter-reset-btn {
  letter-spacing: 0.26px;
  color: #5266e5;
  font-size: 13px;
  font-family: "AcuminPro-Regular";
  padding: 0;
  text-decoration: none;
}

.filter-reset-btn:focus-visible,
.filter-apply-btn:focus-visible {
  outline: 0;
}

.filter-reset-btn:focus,
.filter-apply-btn:focus {
  box-shadow: none;
}

.custom-users-role {
  max-height: 250px;
  overflow-y: auto;
}

.custom-users-role::-webkit-scrollbar {
  display: block !important;
  width: 0.3em !important;
  height: 0.3em !important;
}

.custom-users-role::-webkit-scrollbar-thumb {
  background-color: rgba(31, 30, 30, 0.19) !important;
  border-radius: 3px !important;
}

.p-inputswitch {
  width: 3rem;
  height: 1.75rem;
}

.btn.user-role-btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-color: #5266e5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 35px;
  font-family: "AcuminPro-Regular";
  margin-right: 12px;
  min-width: 80px;
}

.user-role-btn:focus {
  box-shadow: none;
}
</style>
    
    